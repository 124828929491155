export const shallowEqual = (a: unknown, b: unknown): boolean => {
    if (a === b) {
        return true
    }
    if (typeof a !== typeof b) {
        return false
    }
    if (typeof a === 'number' && typeof b === 'number' && isNaN(a) && isNaN(b)) {
        return true
    }
    // eslint-disable-next-line eqeqeq, no-eq-null
    if ((a == null && b != null) || (a != null && b == null)) {
        return false
    }
    if (typeof a === 'object' && typeof b === 'object') {
        for (const k in a) {
            if ((a as any)[k] !== (b as any)[k]) {
                return false
            }
        }
        for (const k in b) {
            if ((a as any)[k] !== (b as any)[k]) {
                return false
            }
        }
        return true
    }
    return false
}

export const isNavigationReload = (w: Window) => {
    if (w.performance && w.performance.navigation) {
        return w.performance.navigation.type === 1
    }

    if (w.performance && typeof w.performance.getEntriesByType === 'function') {
        return (w.performance.getEntriesByType('navigation') as PerformanceNavigationTiming[])
            .map(e => e.type)
            .includes('reload')
    }

    return false
}
