import type {
    SerpicoLastSessionServerResult,
    SerpicoServerContext,
    SerpicoSession,
    SerpicoSessionCustom,
} from '../models'
import { isMobile } from './browser'
import { getCookiePolicyConsent } from './cookiePolicyConsent'
import { loadOrCreateFitIdServer, loadOrCreateFitIdClient } from './fitId'
import { loadOrCreateTrafficSourceSessionClient, loadOrCreateTrafficSourceSessionDataServer } from './trafficSource'

export const createSerpicoSession =
    (sessionCustom: SerpicoSessionCustom = {}) =>
    (w: Window): SerpicoSession => {
        return {
            ...sessionCustom,
            fitId: loadOrCreateFitIdClient(w),
            userAgent: w.navigator.userAgent,
            device: isMobile(w.navigator.userAgent) ? 'Mobile' : 'Desktop',
            language: w.navigator.language,
            screenResolution: `${w.screen.width}x${w.screen.height}`,
            cookiePolicyConsent: getCookiePolicyConsent(w),
            ...loadOrCreateTrafficSourceSessionClient(w),
        }
    }

export const getSerpicoSessionServerData = (c: SerpicoServerContext): SerpicoLastSessionServerResult => {
    const fitIdResult = loadOrCreateFitIdServer(c)
    const sourceSessionDataResult = loadOrCreateTrafficSourceSessionDataServer(c)

    if (fitIdResult._tag === 'created') {
        return {
            _tag: 'created',
            data: {
                fitId: fitIdResult.fitId,
                trafficSourceData: sourceSessionDataResult.trafficSourceData,
                device: isMobile(c.userAgent) ? 'Mobile' : 'Desktop',
                userAgent: c.userAgent ?? '',
            },
            cookies: [fitIdResult.cookie],
        }
    }

    return {
        _tag: 'loaded',
        data: {
            fitId: fitIdResult.fitId,
            trafficSourceData: sourceSessionDataResult.trafficSourceData,
            device: isMobile(c.userAgent) ? 'Mobile' : 'Desktop',
            userAgent: c.userAgent ?? '',
        },
    }
}
