import { gtmDecoratorCustomVar, payloadDecoratorAndFlusher } from '../dataTransformers'
import type { SerpicoEvent, SerpicoEventInteraction } from '../models'
import type { SerpicoSubscriber } from '../store'

interface SstSubscriber {
    onNext: SerpicoSubscriber
}

const decorateInteraction = ({ customVar, payload, ...e }: SerpicoEventInteraction) => ({
    ...e,
    payload: payloadDecoratorAndFlusher(payload),
    customVar: gtmDecoratorCustomVar(customVar),
})

const decorate = (e: SerpicoEvent) => (e.event === 'interaction' ? decorateInteraction(e) : e)

const SERPICO_ERROR_PREFIX = '[SERPICO] '

export const notifyError = (_bufferErrors: Array<unknown>) => {
    if (_bufferErrors.length <= 9) {
        return
    }

    let errorMessage = SERPICO_ERROR_PREFIX
    try {
        errorMessage += JSON.stringify(_bufferErrors)
    } catch (_e) {
        _bufferErrors.splice(0, _bufferErrors.length)
        throw new Error(`${SERPICO_ERROR_PREFIX} sst#1`)
    }

    _bufferErrors.splice(0, _bufferErrors.length)
    throw new Error(errorMessage)
}

export const createSstSubscriber = (
    w: typeof window,
    sstBaseDomain = 'https://serpico-sst.facile.it'
): SstSubscriber => {
    const params = new URLSearchParams(w.location.search)
    const serverPreview = params.get('serverPreview')

    const _bufferErrors: Array<unknown> = []

    const onNext = (e: SerpicoEvent) => {
        w.fetch(`${sstBaseDomain}/nick`, {
            method: 'POST',
            keepalive: true,
            headers: {
                'content-type': 'application/json',
                ...(serverPreview && { 'x-gtm-server-preview': serverPreview }),
            },
            body: JSON.stringify(decorate(e)),
            credentials: 'include',
            cache: 'no-store',
        }).catch(fetchError => {
            if (w.navigator.onLine === false) {
                return
            }

            _bufferErrors.push(fetchError)

            notifyError(_bufferErrors)
        })
    }

    return {
        onNext,
    }
}
