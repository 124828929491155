import { getCookieClient } from '../cookie'
import type { SerpicoStore } from '../store'

declare global {
    interface Window {
        OneTrust?: {
            OnConsentChanged: (cb: () => void) => void
            ToggleInfoDisplay: () => void
        }
    }
}

export const COOKIE_CONSENT = 'OptanonConsent'

export const getCookiePolicyConsent = (w: Window) => {
    const c = getCookieClient(COOKIE_CONSENT)(w)

    if (c) {
        return decodeURIComponent(c)
    }

    return undefined
}

export const watchCookiePolicyConsent = (w: Window, store: SerpicoStore) => {
    let _initialized = false

    const init = () => {
        const ot = w?.OneTrust

        if (typeof ot === 'undefined') {
            return false
        }

        ot.OnConsentChanged(() => {
            store.setSession(s => ({ ...s, cookiePolicyConsent: getCookiePolicyConsent(w) }), 'setCookiePolicyConsent')
        })
        _initialized = true

        return true
    }

    const initDone = init()

    let intervalId: NodeJS.Timer
    if (!initDone) {
        let currentWait = 0
        intervalId = setInterval(() => {
            if (_initialized || init()) {
                clearInterval(intervalId)
                return
            }
            if (currentWait >= 22) {
                clearInterval(intervalId)
                return
            }
            currentWait++
        }, 1000)
    }

    const onDestruct = () => {
        clearInterval(intervalId)
    }

    return { onDestruct }
}
