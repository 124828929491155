import { v4 } from '@lukeed/uuid'
import type { SerpicoCustomVar } from './customVar/modelsCustomVar'
import { customVarFlusher, customVarMerger } from './dataTransformers'
import { isNavigationReload, shallowEqual } from './library'
import type {
    SerpicoEvent,
    PayloadPage,
    SerpicoUser,
    PayloadMisc,
    SerpicoSession,
    PayloadInteractionTypeAction,
    PayloadInteractionTypePageView,
    PayloadEvent,
    PayloadFunnel,
    PayloadInteractionTypeFunnelAction,
    SerpicoMeta,
    SerpicoEventData,
} from './models'

export const SERPICO_SESSION_STORAGE_REFERRER = 'SERPICO_REFERRER'

type PayloadBufferedPageView = PayloadInteractionTypePageView & PayloadPage & PayloadEvent & DispatchBase
type PayloadBufferedInteraction = PayloadInteractionTypeFunnelAction & DispatchFunnelActionPayload
type PayloadBuffered = PayloadBufferedPageView | PayloadBufferedInteraction | DispatchActionPayload

interface EventPartialMeta {
    meta: Pick<SerpicoMeta, 'createdAt'>
}

interface EventBuffered extends EventPartialMeta, DispatchBase {
    payload: PayloadBuffered
}

export interface SerpicoStatePersistent {
    session: SerpicoSession
    user: SerpicoUser
}

export interface SerpicoStateBase extends SerpicoStatePersistent {
    customVar: SerpicoCustomVar
}

export interface SerpicoState extends SerpicoStateBase {
    lastPage?: PayloadPage
    customVarToFlush: SerpicoCustomVar
}

export interface DispatchBase {
    customVar?: SerpicoCustomVar
}
export type DispatchPageViewPayload = Omit<
    PayloadPage,
    'prevTrackPage' | 'pageTitle' | 'pageReferer' | 'pageLocation' | 'pagePath'
> &
    PayloadEvent &
    DispatchBase
export type DispatchActionPayload = PayloadInteractionTypeAction & PayloadEvent & PayloadMisc & DispatchBase
export type DispatchFunnelActionPayload = PayloadFunnel & DispatchBase

export interface SerpicoSubscriber {
    (state: SerpicoEvent): void
}

export interface SerpicoUnsubscriber {
    (): void
}

type ContextChangedActionSession = 'setSession' | 'setCookiePolicyConsent'
type ContextChangedActionUser = 'setUser'
type ContextChangedAction = ContextChangedActionUser | ContextChangedActionSession

export interface SerpicoStore {
    getState: () => SerpicoState
    getStoreId: () => string

    setUser: (f: (u: SerpicoUser) => SerpicoUser) => void
    setSession: (f: (d: SerpicoSession) => SerpicoSession, action?: ContextChangedActionSession) => void
    setCustomVar: (f: (d: SerpicoCustomVar) => SerpicoCustomVar) => void

    dispatchPageView: (d: DispatchPageViewPayload, setter?: (s: SerpicoStateBase) => SerpicoStateBase) => void
    dispatchAction: (d: DispatchActionPayload, setter?: (s: SerpicoStateBase) => SerpicoStateBase) => void
    dispatchFunnelAction: (d: DispatchFunnelActionPayload, setter?: (s: SerpicoStateBase) => SerpicoStateBase) => void

    subscribe: (listener: SerpicoSubscriber) => SerpicoUnsubscriber
}

// -------------------------------------------------------------------------------------
// eventBufferedToSerpicoEvent
// -------------------------------------------------------------------------------------

const eventBufferedToSerpicoEvent = (
    event: EventBuffered,
    serpicoState: SerpicoState
): SerpicoEventData & EventPartialMeta => {
    const baseEvent = {
        event: 'interaction' as const,
        ...event,
        session: serpicoState.session,
        user: serpicoState.user,
        customVar: customVarMerger(serpicoState.customVar, serpicoState.customVarToFlush, event.customVar),
    }

    if (event.payload.interactionType === 'pageView') {
        return {
            ...baseEvent,
            payload: event.payload,
        }
    }

    return {
        ...baseEvent,
        payload: {
            ...event.payload,
            ...(serpicoState.lastPage as PayloadPage),
        },
    }
}

// -------------------------------------------------------------------------------------
// meta
// -------------------------------------------------------------------------------------

const buildPartialMeta = (): EventPartialMeta => ({
    meta: {
        createdAt: new Date().toISOString(),
    },
})

const buildMeta = (createdAt: string, counter: number): SerpicoMeta => ({ createdAt, counter, _id: v4(), version: '0' })

// -------------------------------------------------------------------------------------
// extractPageField
// -------------------------------------------------------------------------------------

const payloadPageKeys: Record<keyof Omit<PayloadPage, 'pageReferer'>, null> = {
    trackPage: null,
    prevTrackPage: null,
    pageCategory: null,
    pageType: null,
    pageTitle: null,
    pageLocation: null,
    pagePath: null,
}

const extractPageFieldFromPayload = <P extends PayloadPage>(p: P): PayloadPage => {
    const ppks = Object.keys(payloadPageKeys)
    return Object.keys(p).reduce((c, k) => (ppks.includes(k) ? { ...c, [k]: (p as any)[k] } : c), {} as PayloadPage)
}

// -------------------------------------------------------------------------------------
// sessionReferrer
// -------------------------------------------------------------------------------------

const setSessionReferrer = (w: Window, referrer: string | undefined) => {
    w.sessionStorage.setItem(SERPICO_SESSION_STORAGE_REFERRER, referrer ?? w.document.referrer)
}
const computateSessionReferrer = (w: Window, lastPage: PayloadPage | undefined) => {
    if (lastPage) {
        return lastPage.pageLocation
    }

    if (isNavigationReload(w)) {
        return w.sessionStorage.getItem(SERPICO_SESSION_STORAGE_REFERRER) ?? ''
    }

    return w.document.referrer
}

// -------------------------------------------------------------------------------------
// createSerpicoStore
// -------------------------------------------------------------------------------------

export const createSerpicoStore = (
    w: Window,
    {
        session,
        user,
        customVar: cvInit,
    }: {
        session: SerpicoSession
        user?: SerpicoUser
        customVar?: SerpicoCustomVar
    }
): SerpicoStore => {
    // -------------------------------------------------------------------------------------
    // init
    // -------------------------------------------------------------------------------------

    const _storeId = v4()

    let _eventCounter = 0

    let _state: SerpicoState = {
        session,
        user: user ?? {
            logged: false,
        },
        customVar: cvInit ?? {},
        customVarToFlush: {},
    }

    const _listeners = new Set<SerpicoSubscriber>()

    const _buffer: Array<EventBuffered> = []
    const _replay: Array<SerpicoEvent> = []
    let _initialized = false

    // -------------------------------------------------------------------------------------
    // run dispatch
    // -------------------------------------------------------------------------------------

    const doDispatch = (event: SerpicoEventData & EventPartialMeta) => {
        const ed = { ...event, meta: buildMeta(event.meta.createdAt, _eventCounter++) }
        _replay.push(ed)
        if (_replay.length > 200) {
            _replay.shift()
        }
        _listeners.forEach(l => l(ed))
    }

    // -------------------------------------------------------------------------------------
    // buffered dispatch
    // -------------------------------------------------------------------------------------

    const bufferedDispatch = (event: EventBuffered) => {
        if (_initialized) {
            doDispatch(eventBufferedToSerpicoEvent(event, _state))

            return tearDownDispatch(event)
        }

        if (event.payload.interactionType === 'pageView') {
            doDispatch(eventBufferedToSerpicoEvent(event, _state))
            flushBufferAndInitialize(event.payload)

            return tearDownDispatch(event)
        }

        _buffer.push(event)
    }

    const flushBufferAndInitialize = (payload: PayloadBufferedPageView) => {
        const lastState = {
            ..._state,
            lastPage: extractPageFieldFromPayload(payload),
        }

        _buffer.forEach(eb => doDispatch(eventBufferedToSerpicoEvent(eb, lastState)))

        _buffer.splice(0, _buffer.length)
        _initialized = true
    }

    const tearDownDispatch = (event: EventBuffered) => {
        if (event.payload.interactionType === 'pageView') {
            setSessionReferrer(w, event.payload.pageReferer)
            _state.lastPage = extractPageFieldFromPayload(event.payload)
        }

        _state.customVarToFlush = customVarFlusher(event.customVar ?? {})
    }

    // -------------------------------------------------------------------------------------
    // dispatch
    // -------------------------------------------------------------------------------------

    const dispatchPageView: SerpicoStore['dispatchPageView'] = ({ customVar, ...p }, setter) => {
        setterSerpicoStateBase(setter)

        const event: EventBuffered = {
            payload: {
                interactionType: 'pageView',
                interactionScope: 'navigation',
                pageLocation: w.location.href,
                pagePath: w.location.pathname,
                pageTitle: w.document.title,
                prevTrackPage: _state.lastPage?.trackPage,
                pageReferer: computateSessionReferrer(w, _state.lastPage),
                ...p,
            },
            customVar,
            ...buildPartialMeta(),
        }

        bufferedDispatch(event)
    }

    const dispatchAction: SerpicoStore['dispatchAction'] = ({ customVar, ...p }, setter) => {
        setterSerpicoStateBase(setter)

        const event: EventBuffered = {
            payload: p,
            customVar,
            ...buildPartialMeta(),
        }

        bufferedDispatch(event)
    }

    const dispatchFunnelAction: SerpicoStore['dispatchFunnelAction'] = ({ customVar, ...p }, setter) => {
        setterSerpicoStateBase(setter)

        const event: EventBuffered = {
            payload: {
                interactionType: 'funnel',
                ...p,
            },
            customVar,
            ...buildPartialMeta(),
        }

        bufferedDispatch(event)
    }

    const dispatchContextChanged = (action: ContextChangedAction) => {
        if (_initialized) {
            const event: EventBuffered = {
                payload: {
                    interactionType: 'contextChanged',
                    interactionScope: 'onPage',
                    eventAction: action,
                    eventCategory: 'appMessage',
                },
                ...buildPartialMeta(),
            }

            bufferedDispatch(event)
        }
    }

    // -------------------------------------------------------------------------------------
    // getter & setter
    // -------------------------------------------------------------------------------------

    const getStoreId = () => _storeId

    const getState = () => _state

    const setterSerpicoStateBase = (f?: (s: SerpicoStateBase) => SerpicoStateBase) => {
        if (f) {
            const { session: s, user: u, customVar: cv } = _state
            _state = { ..._state, ...f({ session: s, user: u, customVar: cv }) }
        }
    }

    const setUser: SerpicoStore['setUser'] = f => {
        const nu = f(_state.user)

        if (shallowEqual(_state.user, nu)) {
            return
        }

        _state = { ..._state, user: nu }

        dispatchContextChanged('setUser')
    }

    const setSession: SerpicoStore['setSession'] = (f, a = 'setSession') => {
        const ns = { ..._state.session, ...f(_state.session) }

        if (shallowEqual(_state.session, ns)) {
            return
        }

        _state = { ..._state, session: ns }

        dispatchContextChanged(a)
    }

    const setCustomVar: SerpicoStore['setCustomVar'] = f => {
        _state = { ..._state, customVar: f(_state.customVar) }
    }

    // -------------------------------------------------------------------------------------
    // subscribe
    // -------------------------------------------------------------------------------------

    const subscribe: SerpicoStore['subscribe'] = listener => {
        _replay.forEach(e => listener(e))
        _listeners.add(listener)

        return () => _listeners.delete(listener)
    }

    // -------------------------------------------------------------------------------------
    // init
    // -------------------------------------------------------------------------------------

    doDispatch({
        event: 'serpicoInit',
        ...buildPartialMeta(),
    })

    // -------------------------------------------------------------------------------------
    // export
    // -------------------------------------------------------------------------------------

    return {
        getState,
        getStoreId,
        setUser,
        setCustomVar,
        setSession,
        dispatchPageView,
        dispatchAction,
        dispatchFunnelAction,
        subscribe,
    }
}
