import type { CustomVarInsuranceRca, SerpicoCustomVar } from './customVar/modelsCustomVar'
import type { FormInfo, PayloadInteraction } from './models'

type StateValue = boolean | number | string | undefined | StateRecord | StateArray

export interface StateRecord {
    readonly [key: keyof any]: StateValue
}

export interface StateArray extends ReadonlyArray<StateValue> {}

type GtmDecorated<A> = A extends boolean
    ? 'S' | 'N'
    : A extends StateRecord | StateArray
    ? { [k in keyof A]: GtmDecorated<A[k]> }
    : A

export function gtmRecursiveDecorator<T>(o: T): GtmDecorated<T> // this is an hack for this https://github.com/microsoft/TypeScript/issues/15300 interface vs type
export function gtmRecursiveDecorator<T extends StateRecord | StateArray>(o: T) {
    const no = (Array.isArray(o) ? [] : {}) as
        | {
              [key: keyof any]: StateValue
          }
        | Array<StateValue>

    for (const k in o) {
        if (typeof o[k] === 'object' && o[k] !== null) {
            no[k] = gtmRecursiveDecorator(o[k] as T)
        } else if (typeof o[k] === 'boolean') {
            no[k] = o[k] ? 'S' : 'N'
        } else if (typeof o[k] !== 'undefined') {
            no[k] = o[k]
        }
    }

    return no
}

type DecoratorOut<I, E> = (Omit<I, keyof E> & E) | undefined

// -------------------------------------------------------------------------------------
// Payload
// -------------------------------------------------------------------------------------

const payloadFormInfoDecorator = (
    formInfo?: FormInfo
): DecoratorOut<
    FormInfo,
    {
        path?: string
        fieldsInError?: string
    }
> | null =>
    formInfo
        ? {
              ...formInfo,
              path: formInfo.path?.join(' > '),
              fieldsInError: formInfo.fieldsInError?.join(', '),
          }
        : null

export const payloadDecoratorAndFlusher = (p: PayloadInteraction) => ({
    funnelInfo: null,
    eventAction: null,
    eventCategory: null,
    eventLabel: null,
    eventCompanyName: null,
    eventProductName: null,
    resultsIndex: null,
    ...p,
    formInfo:
        p.interactionType !== 'pageView' && p.interactionType !== 'funnel'
            ? payloadFormInfoDecorator(p.formInfo)
            : null,
})

// -------------------------------------------------------------------------------------
// CustomVar
// -------------------------------------------------------------------------------------

const getIndustries = (cvs: Array<SerpicoCustomVar>) =>
    [...new Set(cvs.reduce((c, cv) => [...c, ...Object.keys(cv)], [] as string[]))] as Array<keyof SerpicoCustomVar>

const getIndustryKeys = (i: keyof SerpicoCustomVar, cv: SerpicoCustomVar, cvOverwrites: SerpicoCustomVar) => [
    ...new Set(Object.keys(cv[i] ?? {}).concat(Object.keys(cvOverwrites[i] ?? {}))),
]
const getIndustryValue = (cv: SerpicoCustomVar, i: keyof SerpicoCustomVar, v: string) => {
    const io = cv[i]

    return io ? io[v as keyof SerpicoCustomVar[keyof SerpicoCustomVar]] : undefined
}

export const customVarFlusher = (cv: SerpicoCustomVar) =>
    getIndustries([cv]).reduce((cIndustries, industry) => {
        const d = cv[industry]

        return d
            ? {
                  ...cIndustries,
                  [industry]: Object.keys(d).reduce((c, k) => ({ ...c, [k]: null }), d),
              }
            : cIndustries
    }, cv)

export const customVarMerger = (
    cvCommon: SerpicoCustomVar,
    cvFlusher: SerpicoCustomVar,
    cvOverwrites: SerpicoCustomVar = {}
) =>
    getIndustries([cvCommon, cvFlusher, cvOverwrites]).reduce(
        (cIndustries, industry) => ({
            ...cIndustries,
            [industry]: getIndustryKeys(industry, cvCommon, cvOverwrites).reduce(
                (c, k) => ({
                    ...c,
                    [k]: getIndustryValue(cvOverwrites, industry, k) ?? getIndustryValue(cvCommon, industry, k),
                }),
                cvFlusher[industry] ?? {}
            ),
        }),
        cvCommon
    )

export const gtmDecoratorInsuranceRca = (
    insuranceRca: CustomVarInsuranceRca
): DecoratorOut<
    CustomVarInsuranceRca,
    {
        insuranceEffectiveDate?: string
        vehicleRegistrationDateYear?: string
    }
> => ({
    ...insuranceRca,
    insuranceEffectiveDate: insuranceRca.insuranceEffectiveDate?.toISOString().substring(0, 10),
    vehicleRegistrationDateYear: insuranceRca.vehicleRegistrationDateYear?.toISOString().substring(0, 4),
})

export const gtmDecoratorCustomVar = ({ insuranceRca, ...restCustomVar }: SerpicoCustomVar = {}): DecoratorOut<
    SerpicoCustomVar,
    {
        insuranceRca?: ReturnType<typeof gtmDecoratorInsuranceRca>
    }
> => {
    if (insuranceRca) {
        return {
            ...restCustomVar,
            insuranceRca: gtmDecoratorInsuranceRca(insuranceRca),
        }
    }

    return restCustomVar
}
