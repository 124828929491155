import {
    createGtmSubscriber,
    createSstSubscriber,
    getSessionClientHints,
    GTM_ID_FACILE_IT,
    makeGTMUrl,
    partyTownConfigVanilla,
    SerpicoCustomVar,
    SerpicoSessionCustom,
    SerpicoStore,
    SerpicoUser,
    watchCookiePolicyConsent,
} from '@facile-it/serpico-core'
import { createSerpicoStore } from '@facile-it/serpico-core'
import { createSerpicoSession } from '@facile-it/serpico-core'

export interface RunConf {
    state?: { session?: SerpicoSessionCustom; user?: SerpicoUser; customVar?: SerpicoCustomVar }
    dataLayerName?: string
    sst?: boolean
    sstBaseDomain?: string
}

let _store: undefined | SerpicoStore

export const getStore = () => {
    if (typeof _store === 'undefined') {
        throw new Error('Store not runned')
    }

    return _store
}

export const resetStore = () => {
    _store = undefined
}

export const run = (conf: RunConf = {}) => {
    const session = createSerpicoSession(conf.state?.session)(window)

    getSessionClientHints(window).then(ch => {
        if (ch) {
            session.clientHints = ch
        }
    })

    const store = createSerpicoStore(window, {
        session,
        user: conf.state?.user,
        customVar: conf.state?.customVar,
    })

    watchCookiePolicyConsent(window, store)

    const { onNext: onNextGtm } = createGtmSubscriber(window, conf.dataLayerName)

    store.subscribe(onNextGtm)

    if (conf.sst !== false) {
        const { onNext: onNextSst } = createSstSubscriber(window, conf.sstBaseDomain)
        store.subscribe(onNextSst)
    }

    _store = store
}

export const partyTownConfigRegisterGlobal = (config: Parameters<typeof partyTownConfigVanilla>[0] = {}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.partytown = partyTownConfigVanilla(config)
}

export const getGtmFacileItUrl = () => makeGTMUrl(GTM_ID_FACILE_IT)
export { makeGTMUrl }
