import type { SerpicoSession } from '../models'

export const isMobile = (userAgent: string | undefined) => {
    return typeof userAgent === 'string' && /Android|iPhone|iPod|BlackBerry|Opera Mini/i.test(userAgent)
}

export const getSessionClientHints = async (w: Window): Promise<SerpicoSession['clientHints'] | undefined> => {
    const n = w.navigator as any

    if (n.userAgentData && typeof n.userAgentData.getHighEntropyValues === 'function') {
        return await n.userAgentData.getHighEntropyValues([
            'architecture',
            'bitness',
            'fullVersionList',
            'model',
            'platformVersion',
            'platform',
            'mobile',
            'brands',
        ])
    }

    return undefined
}
