import { bindBuildCookieValueToClient, buildCookieValue, getCookieClient } from '../cookie'
import type {
    SerpicoServerContext,
    SerpicoSessionTrafficSource,
    SourceData,
    TrafficSourceData,
    TrafficSourceId,
} from '../models'
import { SERPICO_SESSION_TRK_SECONDS_TIMEOUT } from '../snippet'
import { getUtcSecondSinceEpoch, hashString, nSecondsHaveGoneBy } from '../utilities'

export const SERPICO_TRAFFIC_SOURCE_COOKIE_ID_NAME = 'SERPICO_TRAFFIC_SOURCE_ID'
export const SERPICO_TRAFFIC_SOURCE_COOKIE_DATA_NAME = 'SERPICO_TRAFFIC_SOURCE_DATA'

const trafficSourceCookieDataExpiresDate = () => {
    const date = new Date()
    date.setMonth(date.getMonth() + 6)
    return date
}

interface TrafficSourceSessionFromCookie {
    trafficSourceData: TrafficSourceData
    trafficSourceId: TrafficSourceId | undefined
}

export const trafficSourceIdDecode = (s: any): s is TrafficSourceId => {
    if (typeof s !== 'object' || s === null) {
        return false
    }

    return typeof s.id === 'string' && typeof s.timeS === 'number'
}

export const trafficSourceDataDecode = (s: any): s is TrafficSourceData => {
    if (typeof s !== 'object' || s === null) {
        return false
    }

    return typeof s.data === 'object' && s.data !== null && typeof s.hash === 'string'
}

type SourceParamsKey = Exclude<keyof SourceData, 'referer'>
const sourceParamsMap: Record<SourceParamsKey, string> = {
    utmSource: 'utm_source',
    utmMedium: 'utm_medium',
    utmCampaign: 'utm_campaign',
    gclid: 'gclid',
    fbclid: 'fbclid',
    ttclid: 'ttclid',
}

const buildSourceParamsFromString = (search: string): SourceData => {
    const searchParams = new URLSearchParams(search)

    return Object.keys(sourceParamsMap).reduce((c, p) => {
        const v = searchParams.get(sourceParamsMap[p as SourceParamsKey])

        return v === null ? c : { ...c, [p]: v }
    }, {} as SourceData)
}

const buildSourceParamsFromRecord = (search: Partial<{ [key: string]: string | string[] }> | undefined): SourceData => {
    if (typeof search === 'undefined') {
        return {}
    }

    return Object.keys(sourceParamsMap).reduce((c, p) => {
        const v = search[sourceParamsMap[p as SourceParamsKey]]

        return typeof v !== 'string' ? c : { ...c, [p]: v }
    }, {} as SourceData)
}

export const buildTrafficSourceData = (
    search: string | undefined | Partial<{ [key: string]: string | string[] }>,
    referrer: string | undefined,
    hostname: string
): TrafficSourceData => {
    const sourceParams =
        typeof search === 'string' ? buildSourceParamsFromString(search) : buildSourceParamsFromRecord(search)

    const addReferrer = referrer && referrer.match(new RegExp(`http(s)?://${hostname}`, 'i')) === null

    const data = {
        ...sourceParams,
        ...(addReferrer && { referrer }),
    }

    return {
        data,
        hash: hashString(JSON.stringify(data)),
    }
}

const buildTrafficSourceSession = (
    search: string | undefined,
    referrer: string | undefined,
    hostname: string
): SerpicoSessionTrafficSource => {
    return {
        trafficSourceId: {
            id: getUtcSecondSinceEpoch().toString(),
            timeS: getUtcSecondSinceEpoch(),
        },
        trafficSourceData: buildTrafficSourceData(search, referrer, hostname),
    }
}

const findPrevTrafficSourceSessionData = (
    cookies: Partial<{ [key: string]: string }>
): TrafficSourceData | undefined => {
    try {
        const tsDataS = cookies[SERPICO_TRAFFIC_SOURCE_COOKIE_DATA_NAME]

        if (typeof tsDataS !== 'string') {
            return undefined
        }

        const tsDataO = JSON.parse(tsDataS)

        return trafficSourceDataDecode(tsDataO) ? tsDataO : undefined
    } catch {
        /* istanbul ignore next */
        return undefined
    }
}

export const findPrevTrafficSourceSession = (
    cookies: Partial<{ [key: string]: string }>
): TrafficSourceSessionFromCookie | undefined => {
    try {
        const tsData = findPrevTrafficSourceSessionData(cookies)

        if (tsData === undefined) {
            return undefined
        }

        const tsIdS = cookies[SERPICO_TRAFFIC_SOURCE_COOKIE_ID_NAME]

        if (typeof tsIdS !== 'string') {
            return { trafficSourceData: tsData, trafficSourceId: undefined }
        }

        const tsIdO = JSON.parse(tsIdS)

        return trafficSourceIdDecode(tsIdO)
            ? { trafficSourceData: tsData, trafficSourceId: tsIdO }
            : { trafficSourceData: tsData, trafficSourceId: undefined }
    } catch {
        /* istanbul ignore next */
        return undefined
    }
}

const createTrafficSourceIdCookieClient = (trafficSourceId: SerpicoSessionTrafficSource['trafficSourceId']) =>
    bindBuildCookieValueToClient(createTrafficSourceIdCookieValue(trafficSourceId))

const createTrafficSourceDataCookieClient = (trafficSourceData: SerpicoSessionTrafficSource['trafficSourceData']) =>
    bindBuildCookieValueToClient(createTrafficSourceDataCookieValue(trafficSourceData))

const createTrafficSourceIdCookieValue = (trafficSourceId: SerpicoSessionTrafficSource['trafficSourceId']) =>
    buildCookieValue(SERPICO_TRAFFIC_SOURCE_COOKIE_ID_NAME, JSON.stringify(trafficSourceId), {
        sameSite: 'Lax',
        secure: true,
    })

const createTrafficSourceDataCookieValue = (trafficSourceData: SerpicoSessionTrafficSource['trafficSourceData']) =>
    buildCookieValue(SERPICO_TRAFFIC_SOURCE_COOKIE_DATA_NAME, JSON.stringify(trafficSourceData), {
        sameSite: 'Lax',
        expires: trafficSourceCookieDataExpiresDate(),
        secure: true,
    })

const sourceDataIsEmpty = (sd: SourceData) => Object.keys(sd).length === 0
const sourceDataIsNotEmpty = (sd: SourceData) => Object.keys(sd).length > 0

export const computateTrafficSourceToUse = (
    ns: SerpicoSessionTrafficSource,
    cs: TrafficSourceSessionFromCookie | undefined
): SerpicoSessionTrafficSource => {
    if (cs === undefined) {
        return ns
    }

    if (sourceDataIsNotEmpty(ns.trafficSourceData.data) && ns.trafficSourceData.hash !== cs.trafficSourceData.hash) {
        return ns
    }

    if (
        cs.trafficSourceId === undefined ||
        nSecondsHaveGoneBy(ns.trafficSourceId.timeS, cs.trafficSourceId.timeS, SERPICO_SESSION_TRK_SECONDS_TIMEOUT)
    ) {
        return { ...ns, trafficSourceData: cs.trafficSourceData }
    }

    return {
        trafficSourceData: cs.trafficSourceData,
        trafficSourceId: {
            id: cs.trafficSourceId.id,
            timeS: ns.trafficSourceId.timeS,
        },
    }
}

export const loadOrCreateTrafficSourceSessionClient = (w: Window): SerpicoSessionTrafficSource => {
    const ns = buildTrafficSourceSession(w.location.search, w.document.referrer, w.location.hostname)
    const cs = findPrevTrafficSourceSession({
        [SERPICO_TRAFFIC_SOURCE_COOKIE_ID_NAME]: getCookieClient(SERPICO_TRAFFIC_SOURCE_COOKIE_ID_NAME)(w),
        [SERPICO_TRAFFIC_SOURCE_COOKIE_DATA_NAME]: getCookieClient(SERPICO_TRAFFIC_SOURCE_COOKIE_DATA_NAME)(w),
    })

    const ts = computateTrafficSourceToUse(ns, cs)

    createTrafficSourceIdCookieClient(ts.trafficSourceId)(w)
    createTrafficSourceDataCookieClient(ts.trafficSourceData)(w)

    return ts
}

export const loadOrCreateTrafficSourceSessionDataServer = (
    c: SerpicoServerContext
): { trafficSourceData: TrafficSourceData } => {
    const trafficSourceDataN = buildTrafficSourceData(c.query, c.referer, c.hostname)
    const trafficSourceDataP = findPrevTrafficSourceSessionData(c.cookies)

    if (!trafficSourceDataP) {
        return {
            trafficSourceData: trafficSourceDataN,
        }
    }

    const trafficSourceData = sourceDataIsEmpty(trafficSourceDataN.data) ? trafficSourceDataP : trafficSourceDataN

    return {
        trafficSourceData,
    }
}
