import { v4 } from '@lukeed/uuid'
import { bindBuildCookieValueToClient, buildCookieValue, getCookieClient } from '../cookie'
import type { SerpicoServerContext } from '../models'

interface FitIdBrand {
    readonly FitId: unique symbol
}

export type FitId = FitIdBrand & string

export const SERPICO_FIT_ID_LOCAL_STORAGE_KEY_OLD = 'SERPICO_SESSION_ID_v1'
export const SERPICO_FIT_ID_COOKIE_NAME = 'SERPICO_FIT'

const fitItCookieExpires = () => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 2)
    return date
}

export const fitIdRegex = /^FIT-[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export const fitIdDecode = (s: string | null | undefined): s is FitId => typeof s === 'string' && fitIdRegex.test(s)

export const createFitId = () => `FIT-${v4()}` as FitId

const createFitIdCookieClient = (s: FitId) => bindBuildCookieValueToClient(createFitIdCookieValue(s))

const createFitIdCookieValue = (s: FitId) =>
    buildCookieValue(SERPICO_FIT_ID_COOKIE_NAME, s, {
        expires: fitItCookieExpires(),
        sameSite: 'Lax',
        secure: true,
    })

// TEMP: in order to migrate data from ls to cookie, remove after 2024-08-01
const findFitIdLocalStorage = (w: Window): FitId | null => {
    const s = w.localStorage.getItem(SERPICO_FIT_ID_LOCAL_STORAGE_KEY_OLD)

    if (!fitIdDecode(s)) {
        return null
    }

    // TEMP: in order to migrate data from ls to cookie, active after 2024-08-01
    //w.localStorage.removeItem(SERPICO_FIT_ID_LOCAL_STORAGE_KEY_OLD)
    // TEMP end
    createFitIdCookieClient(s)(w)

    return s
}
const syncFitIdLocalStorageAndCookie = (w: Window, s: FitId): FitId | null => {
    const sLs = w.localStorage.getItem(SERPICO_FIT_ID_LOCAL_STORAGE_KEY_OLD)

    if (!fitIdDecode(sLs)) {
        return null
    }

    w.localStorage.setItem(SERPICO_FIT_ID_LOCAL_STORAGE_KEY_OLD, s)

    return s
}
// TEMP end

export const findFitIdClient = (w: Window): FitId | undefined => {
    const s = getCookieClient(SERPICO_FIT_ID_COOKIE_NAME)(w)

    if (fitIdDecode(s)) {
        // TEMP: in order to migrate data from ls to cookie, remove after 2024-08-01
        syncFitIdLocalStorageAndCookie(w, s)
        // TEMP end
        return s
    }

    // TEMP: in order to migrate data from ls to cookie, remove after 2024-08-01
    const sLs = findFitIdLocalStorage(w)

    return fitIdDecode(sLs) ? sLs : undefined
    // TEMP end
}

export const loadOrCreateFitIdClient = (w: Window): FitId => {
    const s = findFitIdClient(w)

    if (s) {
        return s
    }

    const ns = createFitId()
    createFitIdCookieClient(ns)(w)

    return ns
}

export const loadOrCreateFitIdServer = (
    c: Pick<SerpicoServerContext, 'cookies' | 'hostname'>
): { _tag: 'loaded'; fitId: FitId } | { _tag: 'created'; fitId: FitId; cookie: string } => {
    const fitId = c.cookies[SERPICO_FIT_ID_COOKIE_NAME]

    if (fitIdDecode(fitId)) {
        return { _tag: 'loaded', fitId }
    }

    const fitIdN = createFitId()

    return { _tag: 'created', fitId: fitIdN, cookie: createFitIdCookieValue(fitIdN)(c.hostname) }
}
