export const getCookieClient = (name: string) => (w: Window) => {
    const value = `; ${w.document.cookie}`.split(`; ${name}=`).pop()?.split(';').shift()

    return value && value !== '' ? value : undefined
}

export const setCookieClient =
    (
        name: string,
        value: string,
        option?: {
            expires?: Date
            sameSite?: 'Strict' | 'None' | 'Lax'
            secure?: boolean
        }
    ) =>
    (w: Window) => {
        w.document.cookie = buildCookieValue(name, value, option)(w.location.hostname)
    }

export const bindBuildCookieValueToClient = (bcv: (hostname: string) => string) => (w: Window) => {
    w.document.cookie = bcv(w.location.hostname)
}

export const buildCookieValue =
    (
        name: string,
        value: string,
        option?: {
            expires?: Date
            sameSite?: 'Strict' | 'None' | 'Lax'
            secure?: boolean
        }
    ) =>
    (hostname: string) => {
        const domainMatch = hostname.match(/([a-z0-9-]+\.[a-z]{2,6})$/i)?.[1]
        const domain = domainMatch ? `.${domainMatch}` : hostname

        const expires = option?.expires ? ` expires=${option.expires.toUTCString()};` : ''
        const sameSite = option?.sameSite ? ` SameSite=${option?.sameSite};` : ''
        const secure = option?.secure ? ' Secure;' : ''

        return `${name}=${value}; domain=${domain}; path=/;${expires}${sameSite}${secure}`
    }
