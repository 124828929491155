export const hashString = (s: string): string => {
    let h = 0xdeadbeef
    for (let i = 0; i < s.length; i++) {
        // eslint-disable-next-line no-bitwise
        h = Math.imul(h ^ s.charCodeAt(i), 2654435761)
    }
    // eslint-disable-next-line no-bitwise
    return ((h ^ (h >>> 16)) >>> 0).toString()
}

export const getUtcSecondSinceEpoch = () => {
    const now = new Date()
    const utcMSSinceEpoch = now.getTime() + now.getTimezoneOffset() * 60 * 1e3

    return Math.round(utcMSSinceEpoch / 1e3)
}

const castTimeToMs = (time: number) => parseInt(time.toString().padEnd(13, '0'))

export const nSecondsHaveGoneBy = (now: number, refUtcSeconds: number, seconds: number) => {
    return castTimeToMs(now) - seconds * 1e3 >= castTimeToMs(refUtcSeconds)
}
